import ActionItem from './action-item';
import Actor from './actor';
import ClearToCloseTitleItem from './clear-to-close-title-item';
import DealMessage from './deal-message';
import EarnestMoneyDetail from './earnest-money-detail';
import EntityTitleholder from './entity-titleholder';
import IndividualTitleholder from './individual-titleholder';
import Model, {
    AsyncBelongsTo,
    AsyncHasMany,
    attr,
    belongsTo,
    hasMany
} from '@ember-data/model';
import OpsTeamUser from './ops-team-user';
import Property from 'portal/models/property';
import ReissueRate from './reissue-rate';
import SigningDateRange from './signing-date-range';
import SigningMethod from './signing-method';
import TaxAddress from './tax-address';
import Team from './team';
import TrackerEvent from './tracker-event';
import TrackerEventModel from 'portal/models/tracker-event';
import validateInclusionWhenTrue from 'portal/validators/inclusion-when-true';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhenTrue from 'portal/validators/presence-when-true';
import { DealSides } from 'portal/services/deal-side';
import { TrackerEventStatus } from 'portal/models/tracker-event';
import { capitalize } from '@ember/string';
import {
    validateInclusion,
    validatePresence
} from 'ember-changeset-validations/validators';

export enum DealActorRoles {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    ASSIGNOR = 'ASSIGNOR',
    BORROWER = 'BORROWER'
}

export enum DealStatuses {
    COMPLETE = 'COMPLETE',
    IN_PROGRESS = 'IN PROGRESS'
}

export enum NotaryPreference {
    remote_online_notary = 'REMOTE_ONLINE_NOTARY',
    mobile = 'MOBILE_NOTARY',
    in_person = 'IN_PERSON'
}

export enum EarnestMoneyVendor {
    DWOLLA = 'dwolla',
    STRIPE = 'stripe'
}

export const cpcDisclosureValidations = {
    cpcDisclosureRead: [validateInclusionWhenTrue({ list: [true], key: 'cpcAvailable', message: 'Must acknowledge disclosure' })],
    cpcAccepted: [validateInclusionWhenTrue({ list: [true, false], key: 'cpcAvailable', message: 'Must select an option' })]
};

export const intermediaryValidations = {
    intermediaryPreference: [validatePresence({ presence: true })],
    intermediaryPreferenceYes: [validateInclusion({ list: [true, false] })],
    intermediaryName: [
        validatePresenceWhenTrue({
            presence: true,
            key: 'intermediaryPreferenceYes'
        })
    ],
    intermediaryPhoneNumber: [
        validatePresenceWhenTrue({
            presence: true,
            key: 'intermediaryPreferenceYes'
        }),
        validatePhoneNumber('Intermediary phone number', { allowBlank: true })
    ]
};

export const notaryValidations = {
    notaryPreference: [
        validateInclusion({ list: Object.values(NotaryPreference) })
    ]
};

export const dealBuyerValidations = Object.assign(
    {},
    cpcDisclosureValidations,
    notaryValidations
);

export const dealSellerValidations = Object.assign(
    {},
    cpcDisclosureValidations,
    notaryValidations,
    intermediaryValidations
);

export default class Deal extends Model {
    @attr('number')
        actionItemVersion?: number;

    @attr('string')
        actorRole?: DealActorRoles;

    @attr('date')
        buyerClosingDate?: Date;

    @attr('boolean', { allowNull: true })
        cpcAccepted?: boolean;

    @attr('boolean')
        cpcAvailable?: boolean;

    @attr('boolean', { allowNull: true })
        cpcDisclosureRead?: boolean;

    @attr('date')
        createdAt!: Date;

    @attr('string')
        description!: string;

    @attr('string')
        earnestMoneyVendor?: EarnestMoneyVendor;

    @attr('boolean')
        hasMultipleProperties!: boolean;

    @attr('boolean')
        hasUnreadMessages!: boolean;

    @attr('number')
        incompleteActivityCount?: number;

    @attr('boolean')
        inPersonNotary?: boolean;

    @attr('string')
        intermediaryName?: string;

    @attr('string')
        intermediaryPhoneNumber?: string;

    @attr('string')
        intermediaryPreference?: string;

    @attr('string')
        name?: string;

    @attr('string')
        notaryPreference?: string;

    @attr('string')
        note?: string;

    @attr('number')
        purchasePrice?: number;

    @attr('boolean')
        representative?: boolean;

    @attr('date')
        sellerClosingDate?: Date;

    @attr('string')
        status?: DealStatuses;

    @attr('string')
        statusMessage?: string;

    @attr('string')
        trackerHeadline?: string;

    @attr('date')
        ultimateClosingDate?: Date;

    @attr('boolean')
        ultimateClosingDateVisible?: boolean;

    @attr('number')
        unreadMessageCount?: number;

    @attr()
        signingDateRange?: SigningDateRange;

    @attr('boolean')
        allSigningsCompleteForActorRole?: boolean;

    @attr('string')
        intendedFinancingType?: string;

    @belongsTo('earnest-money-detail')
        earnestMoneyDetail?: AsyncBelongsTo<EarnestMoneyDetail> | EarnestMoneyDetail;

    @belongsTo('property')
        property?: AsyncBelongsTo<Property>;

    @belongsTo('reissue-rate')
        reissueRate?: AsyncBelongsTo<ReissueRate> | ReissueRate;

    @belongsTo('tax-address')
        taxAddress?: AsyncBelongsTo<TaxAddress>;

    @hasMany('action-item', { polymorphic: true })
        actionItems?: AsyncHasMany<ActionItem>;

    @hasMany('actors')
        actors?: AsyncHasMany<Actor>;

    @hasMany('clear-to-close-title-item')
        clearToCloseTitleItems?: AsyncHasMany<ClearToCloseTitleItem> | ClearToCloseTitleItem[];

    @hasMany('deal-message')
        messages?: AsyncHasMany<DealMessage>;

    @hasMany('ops-team-user')
        opsTeamUsers?: AsyncHasMany<OpsTeamUser>;

    @hasMany('property')
        properties?: AsyncHasMany<Property>;

    @hasMany('signing-methods')
        signingMethods?: SigningMethod[];

    @hasMany('teams')
        teams?: AsyncHasMany<Team>;

    @hasMany('titleholders', { polymorphic: true })
        titleholders?:
            | AsyncHasMany<EntityTitleholder | IndividualTitleholder>
            | Array<IndividualTitleholder | EntityTitleholder>;

    @hasMany('tracker-event')
        trackerEvents?: AsyncHasMany<TrackerEvent> | TrackerEventModel[];

    get closingDateForRole(): Date | undefined {
        if (this.isBuyer) { return this.buyerClosingDate; }
        if (this.isSeller) { return this.sellerClosingDate; }

        return this.ultimateClosingDate;
    }

    get isBorrower(): boolean {
        return this.actorRole?.toUpperCase() == DealActorRoles.BORROWER;
    }

    get isBuyer(): boolean {
        return this.actorRole?.toUpperCase() === DealActorRoles.BUYER;
    }

    get isSeller(): boolean {
        return this.actorRole?.toUpperCase() === DealActorRoles.SELLER;
    }

    get lowerActorRole(): string {
        return this.actorRole?.toLowerCase() || '';
    }

    get prettyActorRole(): string {
        return capitalize(this.actorRole?.toLowerCase() || '');
    }

    get propertyCountDisplay(): string {
        if (!this.properties){ return '0 total properties'; }
        if (this.properties.length == 1){ return '1 property'; }
        return `${this.properties.length} total properties`;
    }

    get sortableOpenDate(): Date | undefined {
        return this.createdAt;
    }

    get sortableUltimateClosingDate(): Date | undefined {
        if (this.ultimateClosingDateVisible) {
            return this.ultimateClosingDate;
        }
        return;
    }

    get trackerEventsCompleted() {
        return this.trackerEvents?.filter((trackerEvent) => {
            return trackerEvent.status === TrackerEventStatus.COMPLETED;
        }).length;
    }

    get hasIncompleteActionItems() {
        return !!(this.incompleteActivityCount && this.incompleteActivityCount > 0);
    }

    async latestTitleholder(dealSide: DealSides | undefined): Promise<IndividualTitleholder | EntityTitleholder | undefined> {
        await this.titleholders;

        if (!this.titleholders) { return; }

        const titleholders = await this.titleholdersForSide(dealSide);

        return titleholders[0];
    }

    async titleholdersForSide(dealSide: DealSides | undefined): Promise<Array<IndividualTitleholder | EntityTitleholder>> {
        await this.titleholders;

        if (!this.titleholders) { return []; }

        return this.titleholders.toArray().filter((titleholder) => {
            return titleholder?.dealSide === dealSide;
        });
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        deal: Deal;
    }
}
